import React from "react";

export function CatalogueDocumentation() {
  return (
    <div>
      <article className="prose max-w-none">
        <h1>How to update your data in DirBat?</h1>
      </article>
      <article className="prose print:max-w-none">
        <p>
          The update of the catalogue is done by importing your data in Excel
          format, via your back-office interface.
        </p>
        <p>The excel file must include three (3) sheets:</p>
        <ul>
          <li>
            <strong>PRODUCTS</strong> (mandatory)
            <br />
            This sheet containt the <b>products</b> data that will be displayed
            on the product page.
          </li>
          <li>
            <strong>REFERENCES</strong> (mandatory)
            <br />
            This sheet contains the <b>technical data</b> of the products, which
            will allow the user to filter the references of the same product to
            find the one that suits his needs.
          </li>
          <li>
            <strong>CATEGORIES</strong> (optional, strongly recommended)
            <br />
            This sheet contains the <b>metadata</b> associated with the
            categories. For each category of depth at least 1, you can provide
            the URL of an image, which will illustrate this category.
            <br />
            It allows in addition to set the order of the categories in the
            application.
          </li>
        </ul>
      </article>
      <article className="prose print:max-w-none">
        <div className="flex flex-row items-center justify-start bg-gray-100 p-4 rounded mb-8">
          <div className="text-4xl pr-4">⚠️</div>
          <div>
            Beware to respect <strong>the naming</strong> of sheets and columns.
            <br />
            The import modules analyses your file and searches for the elements
            based on their <em>name</em>, not on their{" "}
            <em>location/order in the sheet</em>.
          </div>
        </div>
      </article>
      <article className="prose print:max-w-none print:prose-sm">
        <h2>PRODUCTS sheet</h2>
        <p>
          The sheet <em>products</em> MUST includes the{" "}
          <strong>12 columns</strong> detailed in the following table :
        </p>
      </article>
      <article className="prose max-w-none">
        <table className="">
          <thead className="sticky top-16 bg-white print:bg-gray-100">
            <tr>
              <th>Column name</th>
              <th>description</th>
              <th>type</th>
              <th>mandatory&nbsp;?</th>
              <th>
                Unique&nbsp;
                <a href={"#note1"} className="">
                  1
                </a>
              </th>
              <th>Sample(s)</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colSpan={7}>
                <p>
                  <a id="note1" />a field marked as{" "}
                  <em>
                    <strong>unique</strong>
                  </em>{" "}
                  implies that the concerned value must be present only once for
                  all your products.
                </p>
              </td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td className="font-bold">publicProductCode</td>
              <td>public product code exposed to the user</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td>unique</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  CABR2.5
                </div>
              </td>
              <td>can be identical to the internalProductCode</td>
            </tr>
            <tr>
              <td className="font-bold">internalProductCode</td>
              <td>Your internal product code</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td>unique</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  1223945453234
                </div>
              </td>
              <td>
                may be identical to the publicProductCode
                <br />
                may be for example an EAN code
              </td>
            </tr>
            <tr>
              <td className="font-bold">eanCodes</td>
              <td>EAN Codes to allow scanning in stores</td>
              <td>text(200)</td>
              <td></td>
              <td></td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  1234567891245
                  <br />
                  1234567891255
                  <br />
                </div>
              </td>
              <td>One EAN code per line</td>
            </tr>
            <tr>
              <td className="font-bold">description</td>
              <td>a marketing description of the product</td>
              <td>text(600)</td>
              <td>mandatory</td>
              <td>&nbsp;</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Wire for all your applications.
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td className="font-bold">brand</td>
              <td>the brand of the product (if you market several brands)</td>
              <td>texte(200)</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  WirePro
                </div>
              </td>
              <td>
                If your catalogue includes several brands, they will be
                filterable in the application. Leave this field empty if you
                have only one brand.
              </td>
            </tr>
            <tr className="break-inside-avoid">
              <td className="font-bold">label</td>
              <td>designation of the product in your language</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Electric red wire 2.5mm
                </div>
              </td>
              <td />
            </tr>
            <tr>
              <td className="font-bold">technicalDrawing</td>
              <td>image or technical drawing designating the dimensions</td>
              <td>public URL</td>
              <td />
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.T.jpg
                </div>
              </td>
              <td>
                file in JPG or PNG format
                <br />
                The dimensions designated must ideally be consistent with the
                attributes of the references
              </td>
            </tr>
            <tr>
              <td className="font-bold">dataSheet</td>
              <td>Technical data sheet</td>
              <td>public URL</td>
              <td />
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.pdf
                </div>
              </td>
              <td>file in PDF format</td>
            </tr>
            <tr>
              <td className="font-bold">declarationsOfPerformances</td>
              <td>DOP</td>
              <td>public URL</td>
              <td />
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.DOP.pdf
                </div>
              </td>
              <td>file in PDF format</td>
            </tr>
            <tr>
              <td className="font-bold">categories</td>
              <td>List of categories in which the product should appear</td>
              <td>text(600)</td>
              <td>mandatory</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  All&nbsp;our&nbsp;products&nbsp;&gt;&nbsp;Consumables&nbsp;&gt;&nbsp;Sheathed
                  cable
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Your&nbsp;problem&nbsp;&gt;&nbsp;Gardening&nbsp;&gt;&nbsp;Home
                  automation&nbsp;&gt;&nbsp;Cable
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Not&nbsp;to&nbsp;be&nbsp;missed&nbsp;&gt;&nbsp;Cable
                </div>
              </td>
              <td>
                <ul>
                  <li>Split the levels of the tree with “&nbsp;&gt;&nbsp;”</li>
                  <li>One line per category.</li>
                  <li>The first line designates the main category.</li>
                </ul>
                <br />
              </td>
            </tr>
            <tr>
              <td className="font-bold">images</td>
              <td>
                List of images displayed in the product gallery, separated by
                carriage returns
              </td>
              <td>Public URLs</td>
              <td>mandatory</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.01.jpg
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.02.jpg
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.03.jpg
                </div>
              </td>
              <td>
                file in JPG or PNG format
                <br />
                The first image in the list will be the main image.
              </td>
            </tr>
            <tr>
              <td className="font-bold">video</td>
              <td>Présentation video hosted on a streaming service</td>
              <td>public URLs</td>
              <td />
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://youtube.com/watch/?v=1EhrG43
                </div>
              </td>
              <td>Supported services : Youtube</td>
            </tr>
          </tbody>
        </table>
      </article>
      <article className="prose print:max-w-none break-before-page">
        <h2>REFERENCES sheet</h2>
        <p>
          This file contains the technical information, which will allow the
          user to filter effectively the references of the same product to find
          the one which is adapted to his need.
        </p>
        <p>
          This file will contain, for a given reference, as many lines as there
          are attributes for the reference in question: for example, one line
          for the color attribute and one line for the length attribute.
        </p>
        <ul>
          <li>
            The same number of lines must exist for each reference of a given
            product.
          </li>
        </ul>
      </article>
      <article className="prose max-w-none">
        <table className="">
          <thead className="sticky top-16 bg-white print:bg-gray-100">
            <tr>
              <th>Column name</th>
              <th>description</th>
              <th>type</th>
              <th>mandatory</th>
              <th>
                Unique&nbsp;
                <a href={"#note1"} className="">
                  1
                </a>
              </th>
              <th>Sample</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-bold">internalProductCode</td>
              <td>
                the product identifier from which this reference is derived
              </td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td></td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  1223945453234
                </div>
              </td>
              <td>must exist in the “PRODUCTS” sheet</td>
            </tr>
            <tr>
              <td className="font-bold">reference</td>
              <td>your reference number</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td rowSpan={3}>
                the triplet reference / attributeName / attributeValue must be
                unique
              </td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  CABR2.5/22/234
                </div>
              </td>
              <td>
                may be identical to the internalProductCode if the product has
                only one reference.
              </td>
            </tr>
            <tr>
              <td className="font-bold">attributeName</td>
              <td>a free attribute name</td>
              <td>text(200)</td>
              <td>mandatory</td>

              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Width
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Diameter
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  A
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Color
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Thickness
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Weight
                </div>
              </td>
              <td>
                Ideally the attributes should be explicit: they are used to help
                the user find the right reference.
                <br />
                It's a product's attribute, not from the final packaging.
              </td>
            </tr>
            <tr>
              <td className="font-bold">attributeValue</td>
              <td>the value of this attribute</td>
              <td>text(200)</td>
              <td>mandatory</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  12
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  Red
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  22,5
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td className="font-bold">attributeUnit</td>
              <td>label of the unit</td>
              <td>text(200)</td>
              <td>optional</td>
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  mm
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  cm
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  in
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  kg
                </div>
              </td>
              <td>
                Use abbreviated units to avoid long labels in the application
                (“l” rather than “liter”)
                <br />
                Leave empty if not applicable (for example if attributeValue is
                “color”).
              </td>
            </tr>
          </tbody>
        </table>
      </article>
      <article className="prose">
        <h2> CATEGORIES sheet</h2>
        <p>
          This file contains the metadata associated with the categories. For
          each category of depth at least 1, you can provide the URL of an
          image, which will illustrate this category.
          <ul>
            <li>
              One category not listed, or whose URL is not an image, will not
              have an image in the application.
            </li>
            <li>
              One category listed but not assigned to any product will be
              ignored.
            </li>
          </ul>
        </p>
      </article>
      <article className="prose max-w-none">
        <table>
          <thead className=" print:bg-gray-100">
            <tr>
              <th colSpan={7}>One line per category</th>
            </tr>
            <tr>
              <th>Field name</th>
              <th>description</th>
              <th>type</th>
              <th>mandatory</th>
              <th>Unique</th>
              <th>Sample(s)</th>
              <th>Comments</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="font-bold">categoryPath</td>
              <td>the complete path of the category</td>
              <td>text(200)</td>
              <td />
              <td>unique</td>
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 whitespace-nowrap">
                  All our products &gt; Consumables &gt; Sheathed cable
                </div>
              </td>
              <td>must exist in the “PRODUCTS” sheet</td>
            </tr>
            <tr>
              <td className="font-bold">imageUrl</td>
              <td>URL of the image illustrating the category</td>
              <td>public URL</td>
              <td />
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  https://pim.cablepro.com/medias/cabr2.5.01.jpg
                </div>
              </td>
              <td>
                must be a publicly accessible and valid URL.
                <br />
                must be a JPG or PNG image.
              </td>
            </tr>
            <tr>
              <td className="font-bold">ordre</td>
              <td>order</td>
              <td>order number of the category, for list displays</td>
              <td />
              <td />
              <td>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  1
                </div>
                <div className="bg-gray-200 rounded-full px-4 mb-2 inline-block mr-2 whitespace-nowrap">
                  100
                </div>
              </td>
              <td>if the order is absent, the rankings will be random</td>
            </tr>
          </tbody>
        </table>
      </article>
    </div>
  );
}
