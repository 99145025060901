import React from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import ModuleRoutes from "./Routes";
import { CatalogueDocumentation } from "./Basic";
import { menuItem, SideMenu } from "../../UIKit/Header";
import { FAQ } from "./FAQ";
import { DirBatPrinciples } from "./DirBatPrinciples";
import { DealersDocumentation } from "./DealersDocumentation";

export function DownloadAssetLink(props: {
  assetURL: string;
  fileName: string;
  label: string;
}) {
  const handleDownload = () => {
    fetch(props.assetURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = props.fileName;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  return (
    <span
      className="underline cursor-pointer text-sm text-gray-500"
      onClick={handleDownload}
    >
      {props.label}
    </span>
  );
}

export function DocumentationModule() {
  const path = useLocation();
  const { clientId, supplierId } = useParams<{
    clientId: string;
    supplierId: string;
  }>();
  const documentationMenuItems: menuItem[] = [
    {
      label: "Import a catalogue",
      route: ModuleRoutes(clientId, supplierId).doc_import_catalogues(
        "dirbat_xls"
      ),
      neededAbilities: [],
    },
    {
      label: "Import dealers",
      route: ModuleRoutes(clientId, supplierId).doc_import_dealers(
        "dirbat_xls"
      ),
      neededAbilities: [],
    },
    /*    {
      label: "FAQ",
      route: ModuleRoutes(clientId, supplierId).faq,
      neededAbilities: [],
    },
    {
      label: "Start with DirBat",
      route: ModuleRoutes(clientId, supplierId).doc_principles,
      neededAbilities: [],
    },*/
  ];
  return (
    <div className={"flex flex-row"}>
      <SideMenu items={documentationMenuItems} currentRoute={path.pathname} />
      <div className="w-4/5 pt-8 print:w-full">
        <Switch>
          <Route
            path={ModuleRoutes(
              ":clientId",
              ":supplierId"
            ).doc_import_catalogues(":format")}
            exact
          >
            <CatalogueDocumentation />
          </Route>
          <Route
            path={ModuleRoutes(":clientId", ":supplierId").doc_import_dealers(
              ":format"
            )}
            exact
          >
            <DealersDocumentation />
          </Route>
          <Route path={ModuleRoutes(":clientId", ":supplierId").faq} exact>
            <FAQ />
          </Route>
          <Route
            path={ModuleRoutes(":clientId", ":supplierId").doc_principles}
            exact
          >
            <DirBatPrinciples />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
