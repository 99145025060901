import React, { createContext, useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { TeamRoutes } from "./app/Routes";
import { CenterInScreen } from "./UIKit/CenterInScreen";
import { Loading } from "./UIKit/Loading";
import { APIClient } from "./utilities/APIClient";
import { dirbatLocale } from "./translations/translations";
import { Application } from "./modules/Apps/Application";
import { useIntl } from "react-intl";

export type JWToken = string;

export type UserRole =
  | "ROLE_SUPPLIER"
  | "ROLE_SUPPLIER_OWNER"
  | "ROLE_APPS_LIST"
  | "ROLE_DIRBAT_ADMIN"
  | "ROLE_SUPPLIER_USERS_WRITE"
  | "ROLE_SUPPLIER_METRICS_READ"
  | "ROLE_CMS_WRITE"
  | "ROLE_OFFER_REVIEW"
  | "ROLE_SUPPLIER_USERS_READ"
  | "ROLE_NEWS_WRITE"
  | "ROLE_VIDEO_WRITE"
  | "ROLE_EXHIBITION_WRITE"
  | "ROLE_MEETUP_WRITE"
  | "ROLE_PRODUCT_NEWS_WRITE"
  | "ROLE_CMS_READ"
  | "ROLE_NEWS_READ"
  | "ROLE_VIDEO_READ"
  | "ROLE_EXHIBITION_READ"
  | "ROLE_PRODUCT_NEWS_READ"
  | "ROLE_MEETUP_READ";
export type AbilityFeatures =
  | "apps"
  | "contributors"
  | "contentManagement"
  | "privacyPolicy"
  | "settings"
  | "metrics"
  | "catalogues"
  | "dealers"
  | "community";

export interface Ability {
  action: "list" | "edit";
  subject: AbilityFeatures;
  scope?: AbilityScope;
}

export interface AbilityScope {
  id: string;
}

export interface User {
  supplier: string;
  name?: string;
  token: JWToken;
  roles: UserRole[];
}

export interface PKCE {
  challenge: string;
  verifier: string;
}

export interface AuthClient {
  retrieveToken: (code: string, codeVerifier: string) => Promise<JWToken>;
  authorizeURL: (pkce: PKCE) => string;
  logoutURL: () => string;
}

function userHasRole(user: User, role: UserRole) {
  return user.roles.includes(role);
}

export interface UserData {
  email: string;
  roles: UserRole[];
  supplier: {
    name: string;
    logo: string;
  };
  name: string;
  clientId: string;
  team: {
    id: string;
  };
  locale: dirbatLocale;
}

export function userHasAbility(
  user: User,
  ability: Ability,
  mobileApp?: Application
): boolean {
  if (
    ability.subject === "apps" &&
    userHasRole(user, "ROLE_DIRBAT_ADMIN") &&
    !ability.scope
  ) {
    return true;
  }
  if (ability.subject === "apps" && userHasRole(user, "ROLE_APPS_LIST")) {
    return ability.action === "list";
  }
  if (
    ability.subject === "contributors" &&
    (userHasRole(user, "ROLE_SUPPLIER_OWNER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope
  ) {
    return true;
  }
  if (
    ability.subject === "settings" &&
    (userHasRole(user, "ROLE_SUPPLIER_OWNER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope
  ) {
    return ability.action !== "edit";
  }
  if (
    ability.subject === "privacyPolicy" &&
    (userHasRole(user, "ROLE_SUPPLIER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope
  ) {
    return true;
  }
  if (
    ability.subject === "contentManagement" &&
    userHasRole(user, "ROLE_CMS_WRITE") &&
    ability.scope &&
    mobileApp?.enabledFeatures.includes("contentManagement")
  ) {
    return true;
  }
  if (
    ability.subject === "community" &&
    (userHasRole(user, "ROLE_SUPPLIER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope &&
    mobileApp?.enabledFeatures.includes("community")
  ) {
    return true;
  }
  if (
    ability.subject === "catalogues" &&
    (userHasRole(user, "ROLE_SUPPLIER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope &&
    mobileApp?.enabledFeatures.includes("catalogues")
  ) {
    return true;
  }
  if (
    ability.subject === "metrics" &&
    (userHasRole(user, "ROLE_SUPPLIER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope &&
    mobileApp?.enabledFeatures.includes("metrics")
  ) {
    return true;
  }
  if (
    ability.subject === "dealers" &&
    (userHasRole(user, "ROLE_SUPPLIER") ||
      userHasRole(user, "ROLE_DIRBAT_ADMIN")) &&
    ability.scope &&
    mobileApp?.enabledFeatures.includes("dealers")
  ) {
    return true;
  }
  return false;
}

export interface AuthControl {
  userCan: (
    user: User,
    permissions: Ability[],
    application?: Application
  ) => boolean;
}

const authControl: AuthControl = {
  userCan: (user: User, permissions: Ability[], application?: Application) => {
    // if no permissions are requested, we deny, just in case.
    if (permissions.length === 0) {
      return false;
    }
    return permissions.reduce(
      (hasAllAbilitiesSoFar: boolean, currentAbility) => {
        if (!currentAbility) {
          return false;
        }
        return (
          hasAllAbilitiesSoFar &&
          userHasAbility(user, currentAbility, application)
        );
      },
      true
    );
  },
};

export const ADMIN_APPLICATION: Application = {
  id: "",
  clientId: "",
  supplierId: "",
  teamId: "",
  identifier: "",
  name: "Applications",
  secondaryColor: "",
  status: "testing",
  supplierLogo: "",
  supplierName: "Administration",
  countryCode: "fr",
  languageCode: "fr",
  enabledFeatures: [],
  appstoreUrl: "",
  playstoreUrl: "",
};

export const ApplicationContext = createContext<Application>(ADMIN_APPLICATION);

const authContext = createContext<any>({});

export function useAuth() {
  return useContext(authContext);
}

export interface JWTokenPayload {
  iss?: any;
  sub?: any;
  aud?: any;
  exp?: any;
  nbf?: any;
  iat?: any;
  jti?: any;
  name?: any;
}

export interface JWTokenHeader {
  alg: string;
  typ: string;
}

// We keep this function for potential future reference, but it's not needed
/*function extractTokenData(token: JWToken): JWTokenPayload {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}*/

function useProvideAuth() {
  const [user, setUser] = useState<User | false>(false);

  async function authenticate(token: JWToken) {
    try {
      const userData = await new APIClient(token, "en").getUserData();
      const userFromToken = {
        token: token,
        name: userData.name,
        supplier: userData.supplier?.name,
        roles: userData.roles,
        clientId: userData.clientId,
        teamId: userData.team.id,
        locale: userData.locale,
      };
      setUser(userFromToken);
      return Promise.resolve(userFromToken);
    } catch (e: any) {
      //console.log(e);
    }
  }

  return {
    user,
    authenticate,
    control: authControl,
  };
}

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function AuthHandler(props: {
  authClient: AuthClient;
  codeVerifier: string;
}) {
  const authorizationCode = new URLSearchParams(useLocation().search).get(
    "code"
  );
  const { authenticate, user } = useAuth();
  const intl = useIntl();
  useEffect(() => {
    const token = props.authClient.retrieveToken(
      authorizationCode!,
      props.codeVerifier
    );
    token.then((t) => {
      authenticate(t);
    });
  }, []);
  if (user) {
    const storage = window.sessionStorage;
    if (storage.getItem("route_requested")) {
      const route_requested = storage.getItem("route_requested");
      return <Redirect to={route_requested!} />;
    }
    return <Redirect to={TeamRoutes(user.teamId).apps} />;
  }
  return (
    <CenterInScreen>
      <Loading
        label={intl.formatMessage({
          id: "checking.everything.is.ok",
          defaultMessage: "Controls in progress",
        })}
      />
    </CenterInScreen>
  );
}

export function getPkceFromStorage(): PKCE {
  const storage = window.sessionStorage;
  return {
    challenge: storage.getItem("code_challenge")!,
    verifier: storage.getItem("code_verifier")!,
  };
}

export const abilities = (clientId: string): { [key: string]: Ability } => {
  return {
    "apps:list": { action: "list", subject: "apps" },
    "apps:edit": { action: "edit", subject: "apps" },
    "contributors:list": {
      action: "list",
      subject: "contributors",
      scope: { id: clientId },
    },
    "dealers:list": {
      action: "list",
      subject: "dealers",
      scope: { id: clientId },
    },
    "dealers:edit": {
      action: "edit",
      subject: "dealers",
      scope: { id: clientId },
    },
    "contentManagement:list": {
      action: "list",
      subject: "contentManagement",
      scope: { id: clientId },
    },
    "catalogues:list": {
      action: "list",
      subject: "catalogues",
      scope: { id: clientId },
    },
    "settings:edit": {
      action: "edit",
      subject: "settings",
      scope: { id: clientId },
    },
    "settings:list": {
      action: "list",
      subject: "settings",
      scope: { id: clientId },
    },
    "metrics:list": {
      action: "list",
      subject: "metrics",
      scope: { id: clientId },
    },
    "privacyPolicy:edit": {
      action: "edit",
      subject: "privacyPolicy",
      scope: { id: clientId },
    },
    "communityManagement:list": {
      action: "list",
      subject: "community",
      scope: { id: clientId },
    },
  };
};
